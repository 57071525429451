<template>
  <!--  <el-row class="dashboard-container" id="dashboard-index-container">-->
  <div class="dashboard-container">
    <div class="topper-wrapper">
      <div class="count-wrapper">
        <div class="title">尊贵的金昇平台用户，您已使用金昇平台{{ pageData?.useDay || 0 }}天</div>
        <div class="count-box">
          <div class="count-item">
            <img src="@/assets/img/index/icon-nonghu.png" alt="" class="left-img">
            <div class="right">
              <div class="name">农户数量</div>
              <div class="num">{{ pageData?.farmerSize || 0 }}<span class="unit">个</span></div>
            </div>
          </div>
          <div class="count-item">
            <img src="@/assets/img/index/icon-kaifadianzhan.png" alt="" class="left-img">
            <div class="right">
              <div class="name">开发中电站数量</div>
              <div class="num">{{ pageData?.developing || 0 }}<span class="unit">个</span></div>
            </div>
          </div>
          <div class="count-item">
            <img src="@/assets/img/index/icon-wanchengdianzhan.png" alt="" class="left-img">
            <div class="right">
              <div class="name">完成电站数量</div>
              <div class="num">{{ pageData?.powerStationSize || 0 }}<span class="unit">个</span></div>
            </div>
          </div>
          <div class="count-item">
            <img src="@/assets/img/index/icon-zhuangjirongliang.png" alt="" class="left-img">
            <div class="right">
              <div class="name">装机容量</div>
              <div class="num">{{ (pageData?.capacity || 0) / 1000 }}<span class="unit">kw</span></div>
            </div>
          </div>
        </div>
      </div>
      <div class="control-wrapper">
        <div class="welcome">欢迎您，{{ serviceData?.name || '-' }}</div>
        <div class="company">
          <img src="@/assets/img/index/icon-company.png" alt="" class="c-img">
          <span class="name">{{ serviceData?.developerName }}</span>
          <span class="version" v-if="serviceData && serviceData.versionName">{{ serviceData.versionName }}</span>
        </div>
        <div class="info-box">
          <div class="info-item">
            <div class="info">
              <span class="name">管理员</span><span class="value">{{ serviceData?.admin || '-' }}</span>
            </div>
            <div class="opr" @click="handleChangeAdmin">{{ serviceData && serviceData.canChange ? '修改' : '' }}</div>
          </div>
          <div class="info-item">
            <div class="info"><span class="name">电子签份数</span><span
                class="value">{{ serviceData?.signSize || 0 }}份</span></div>
            <div class="opr"><!--购买--></div>
          </div>
          <div class="info-item">
            <div class="info"><span class="name">服务到期时间</span><span
                class="value">{{ serviceData?.endTime || '-' }}</span></div>
            <div class="opr"><!--续费--></div>
          </div>
        </div>
        <div class="to-control" @click="toControlPage">进入企业控制台</div>
      </div>
    </div>
    <div class="main-wrapper">
      <div class="title">开发中电站数据总览</div>
      <el-row :gutter="24">
        <el-col :span="8">
          <div class="data-item">
            <div class="name">用户管理</div>
            <div class="data-div">
              <div class="data-box">
                <div class="data-name">待派单</div>
                <div class="data-val">{{ pageData?.farmerInit || 0 }}</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="data-item">
            <div class="name">踏勘阶段</div>
            <div class="data-div">
              <div class="data-box">
                <div class="data-name">待接单</div>
                <div class="data-val">{{ pageData?.farmerWait || 0 }}</div>
              </div>
              <div class="data-box">
                <div class="data-name">踏勘中</div>
                <div class="data-val">{{ pageData?.farmerTk || 0 }}</div>
              </div>
              <div class="data-box">
                <div class="data-name">待审核</div>
                <div class="data-val">{{ pageData?.farmerExamine || 0 }}</div>
              </div>
              <div class="data-box">
                <div class="data-name">审核不通过</div>
                <div class="data-val red">{{ pageData?.farmerFail || 0 }}</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="data-item">
            <div class="name">合同管理</div>
            <div class="data-div">
              <div class="data-box">
                <div class="data-name">待发起</div>
                <div class="data-val">{{ pageData?.signInit || 0 }}</div>
              </div>
              <div class="data-box">
                <div class="data-name">签署中</div>
                <div class="data-val">{{ pageData?.signIng || 0 }}</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="data-item">
            <div class="name">设计阶段</div>
            <div class="data-div">
              <div class="data-box">
                <div class="data-name">待上传</div>
                <div class="data-val">{{ pageData?.sjInit || 0 }}</div>
              </div>
              <div class="data-box">
                <div class="data-name">待审核</div>
                <div class="data-val">{{ pageData?.sjExamine || 0 }}</div>
              </div>
              <div class="data-box">
                <div class="data-name">审核不通过</div>
                <div class="data-val red">{{ pageData?.sjFail || 0 }}</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="data-item">
            <div class="name">项目物料</div>
            <div class="data-div">
              <div class="data-box">
                <div class="data-name">待发货</div>
                <div class="data-val">{{ pageData?.wlInit || 0 }}</div>
              </div>
              <div class="data-box">
                <div class="data-name">运输中</div>
                <div class="data-val">{{ pageData?.wlIng || 0 }}</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="data-item">
            <div class="name">施工验收</div>
            <div class="data-div">
              <div class="data-box">
                <div class="data-name">待派单</div>
                <div class="data-val">{{ pageData?.sgInit || 0 }}</div>
              </div>
              <div class="data-box">
                <div class="data-name">待接单</div>
                <div class="data-val">{{ pageData?.sgWait || 0 }}</div>
              </div>
              <div class="data-box">
                <div class="data-name">施工中</div>
                <div class="data-val">{{ pageData?.sgIng || 0 }}</div>
              </div>
              <div class="data-box">
                <div class="data-name">待审核</div>
                <div class="data-val">{{ pageData?.sgExamine || 0 }}</div>
              </div>
              <div class="data-box">
                <div class="data-name">审核不通过</div>
                <div class="data-val red">{{ pageData?.sgFail || 0 }}</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="data-item">
            <div class="name">并网管理</div>
            <div class="data-div">
              <div class="data-box">
                <div class="data-name">待并网</div>
                <div class="data-val">{{ pageData?.bwInit || 0 }}</div>
              </div>
              <div class="data-box">
                <div class="data-name">待审核</div>
                <div class="data-val">{{ pageData?.bwExamine || 0 }}</div>
              </div>
              <div class="data-box">
                <div class="data-name">审核不通过</div>
                <div class="data-val red">{{ pageData?.bwFail || 0 }}</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="data-item">
            <div class="name">结算管理</div>
            <div class="data-div">
              <div class="data-box">
                <div class="data-name">待结算</div>
                <div class="data-val">{{ pageData?.jsInit || 0 }}</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--变更管理员弹框-->
    <el-dialog title="管理员变更" :visible.sync="admin_dialog" width="35%"
               :close-on-click-modal="false">
      <div>
        <el-form :model="adminForm" :rules="adminRules" label-width="110px" ref="adminForm">
          <el-form-item label="请选择管理员" prop="adminId">
            <el-select v-model="adminForm.adminId" clearable style="width: 100%;" :placeholder="'请选择'">
              <el-option v-for="iter in admin_options"
                         :key="iter.id"
                         :label="iter.name"
                         :value="iter.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="admin_dialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="adminSubmit('adminForm')">提 交</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {allAdmins, changeAdmin, indexData, indexServiceInfo} from '@/api/common'

export default {
  name: "Index",
  components: {},
  data() {
    return {
      loading: false,
      pageData: null,
      serviceData: null,
      //
      admin_dialog: false,
      admin_options: [],
      adminForm: {
        adminId: '',
      },
      adminRules: {
        adminId: [
          {required: true, message: '请选择管理员', trigger: 'blur'}
        ]
      }
    }
  },
  created() {

  },
  mounted() {
    this.getData()
    this.getServiceInfo()
    this.getAllAdmin()
  },
  methods: {
    getData() {
      let that = this
      indexData().then(res => {
        that.pageData = res
      })
    },
    getServiceInfo() {
      indexServiceInfo().then(res => {
        this.serviceData = res
      })
    },
    getAllAdmin() {
      allAdmins().then(res => {
        this.admin_options = res
      })
    },
    // 更改管理员
    handleChangeAdmin() {
      if (this.serviceData && this.serviceData.canChange) {
        this.adminForm.adminId = ''
        this.admin_dialog = true
      }
    },
    adminSubmit(formName) {
      let that = this
      that.$refs[formName].validate((valid) => {
        if (!valid) return
        changeAdmin(that.adminForm.adminId).then(() => {
          that.getServiceInfo()
          that.admin_dialog = false
          that.$message.success('操作成功')
        }).catch(err => {
          console.log('变更管理员失败', err)
        })
      })
    },
    toControlPage() {
      this.$router.push('/admin/controlBoard')
    },
  }
}
</script>

<style lang="scss" scoped>
.dashboard-container {
  padding: 24px;

  .topper-wrapper {
    display: flex;
    height: 286px;

    .count-wrapper {
      background: #ffffff;
      height: 286px;
      flex: 1;
      padding: 0 24px 24px 24px;

      .title {
        padding: 24px 0;
        font-weight: 500;
        font-size: 20px;
        color: #2F3133;
        line-height: 28px;
        text-align: left;
        border-bottom: 1px solid #EEF1F5;
      }

      .count-box {
        display: flex;
        padding-top: 24px;

        .count-item {
          width: 270px;
          height: 162px;
          background: #F5F7FA;
          border-radius: 4px;
          padding: 49px 24px;
          margin-left: 24px;

          &:first-child {
            margin-left: 0;
          }

          .left-img {
            width: 64px;
            height: 64px;
            vertical-align: middle;
          }

          .right {
            display: inline-block;
            vertical-align: middle;
            padding-left: 12px;

            .name {
              font-weight: 400;
              font-size: 14px;
              color: #8D9094;
              line-height: 22px;;
            }

            .num {
              padding-top: 8px;
              font-weight: 500;
              font-size: 24px;
              color: #2F3133;
              line-height: 32px;

              .unit {
                font-weight: 400;
                font-size: 14px;
                color: #8D9094;
                line-height: 22px;
                padding-left: 4px;
              }
            }
          }
        }
      }

    }

    .control-wrapper {
      height: 286px;
      width: 408px;
      margin-left: 24px;
      background: #fff;
      padding: 24px;

      .welcome {
        font-weight: 500;
        font-size: 20px;
        color: #2F3133;
        line-height: 28px;
      }

      .company {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-top: 24px;
        justify-content: center;

        & > img {
          width: 40px;
          height: 40px;
        }

        .name {
          max-width: 200px;
          height: 22px;
          line-height: 22px;
          padding: 0 8px;
          font-weight: 400;
          font-size: 14px;
          color: #2F3133;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .version {
          font-size: 12px;
          color: #FFFFFF;
          line-height: 24px;
          height: 24px;
          padding: 0 8px;
          border-radius: 5000px;
          background: #FA9600;
        }
      }

      .info-box {
        padding: 16px 30px;

        .info-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 4px;

          .info {
            display: flex;
            flex: 1;

            & > span {
              flex: 1;
            }

            .name {
              text-align: right;
              font-weight: 400;
              font-size: 14px;
              color: #8D9094;
              line-height: 22px;
              padding-right: 12px;
            }

            .value {
              text-align: left;
              font-weight: 400;
              font-size: 14px;
              color: #2F3133;
              line-height: 22px;
              padding-left: 12px;
            }
          }


          .opr {
            width: 28px;
            font-weight: 400;
            font-size: 14px;
            color: #0D6CE4;
            line-height: 22px;
            cursor: pointer;
          }
        }
      }

      .to-control {
        background: #0D6CE4;
        color: #fff;
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin: 0 30px;
      }
    }
  }

  .main-wrapper {
    margin-top: 24px;
    background: #FFF;
    padding: 0 24px;

    .title {
      padding: 24px 0;
      font-weight: 500;
      font-size: 20px;
      color: #2F3133;
      line-height: 28px;
      text-align: left;
    }

    .data-item {
      //width: 510px;
      height: 126px;
      margin-bottom: 24px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #E6EBF0;
      padding: 16px;

      .name {
        font-weight: 500;
        font-size: 16px;
        color: #2F3133;
        line-height: 24px;
        border-left: 4px solid #0D6CE4;
        padding-left: 8px;
      }

      .data-div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 16px 8px 0 8px;

        .data-box {
          .data-name {
            width: 96px;
            font-weight: 400;
            font-size: 14px;
            color: #2F3133;
            line-height: 22px;
          }

          .data-val {
            padding-top: 4px;
            font-weight: 500;
            font-size: 20px;
            color: #0D6CE4;
            line-height: 28px;

            &.red {
              color: #FC474C;
            }
          }
        }
      }
    }
  }
}
</style>